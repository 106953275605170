<template>
  <div class="L21BX-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>

    <el-dialog title="" width="500px" top="30vh" :visible.sync="dialogVisibleUpdate" :close-on-click-modal="false">
      <div class="dialog_content">
        <div>执行升级大约需要2分钟，请不要断电</div>
      </div>
      <div class="dialog_bottom">
        <el-button @click="closeFun">取消</el-button>
        <el-button type="primary" @click="sureBtn">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  let validMAC = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("MAC格式不对"));
      } else {
        callback();
      }
    }
  };
  let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("货管员格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    props: {
    bindPlatformType: {
      type: Number,
      default: "",
    },
  },
    data() {
      let _this = this;
      return {
        opt: {
          title: "",
          search: [],
          columns: [{
              label: "MAC",
              key: "mac",
            },
            {
              label: "名称",
              key: "name"
            },
            {
              label: "IP",
              key: "ip"
            },
            {
              label: "商家名称",
              key: "merchantName"
            },
            {
              label: "门店位置",
              key: "location"
            },
            {
              label: "货柜位置",
              key: "address"
            },
            {
              label: "电量",
              key: "batteryStr"
            },
            {
              label: "绑定时间",
              key: "createdTime"
            },
            {
              label: "联网状态",
              key: "onlineStateStr"
            },
            {
              label: "货品状态",
              key: "goodsStateStr"
            },
            {
              label: "固件版本",
              key: "version"
            },
            {
              label: "操作",
              key: "action",
              type: "action-but",
              opt: {
                list: [{
                    label: "详情",
                    on(row) {
                      _this.$router.push({
                        path: "/main/containerManagement/detail/L21BX",
                        query: {
                          id: row.id,
                          type: row.type,
                          mac: row.mac
                        }
                      })
                    }
                  },
                  // {
                  //   label: "升级固件",
                  //   on(row) {
                  //     // 升级前 显示提示
                  //     _this.dialogVisibleUpdate = true;
                  //     _this.rowData = row;

                  //   }
                  // }
                ]
              }
            }
          ],
          buttons: [{
            type: 2,
            on(data) {
              if (data.ids.length > 1) {
                return _this.$message({
                  showClose: true,
                  message: "不支持多个删除,只支持单个删除!",
                  type: "warning"
                });
              }
              const h = _this.$createElement;
              _this
                .$msgbox({
                  title: "删除提示",
                  message: h("p", null, [
                    h(
                      "span", {
                        style: "color: #333;font-size: 16px;margin-left:98px"
                      },
                      "确定要删除货柜吗？"
                    )
                  ]),
                  showCancelButton: true,
                  confirmButtonText: "确定",
                  cancelButtonText: "取消"
                })
                .then(action => {
                  _this.get("mall-service/counter/delete/" + data.ids[0]).then(res => {
                    _this.$message({
                      showClose: true,
                      message: "删除货柜成功！",
                      type: "success"
                    });
                    data.refresh();
                  });
                });
            }
          }],
          exportButtons: [{
              type: "Export",
              url: this.EXport + "/mall-service/counter/export",
              listName: '货柜列表'
          }]
        },
        roomList: [],
        dialogVisibleUpdate: false,
        rowData: '',
        merchantFlag: false,
        search1:  [{
              key: "groupName",
              label: "门店名称",
              type: "selectCity",
              opt: {
                list: []
              }
            }, {
              key: "mac",
              label: "MAC",
              maxlength: 32,
              rules: [{
                validator: validMAC,
                trigger: ['blur']
              }]
            },
            {
              key: "location",
              label: "门店位置",
              maxlength: 200,
            },
            {
              key: "address",
              label: "货柜位置",
            },
            {
              key: "onlineState",
              label: "联网状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "在线",
                    value: 1
                  },
                  {
                    label: "离线",
                    value: 0
                  }
                ]
              }
            },
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
                change(data) {
                  _this.getStoreList(data)
                }
              }
            }],
            search2:  [{
              key: "mac",
              label: "MAC",
              maxlength: 32,
              rules: [{
                validator: validMAC,
                trigger: ['blur']
              }]
            },
            {
              key: "onlineState",
              label: "联网状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "在线",
                    value: 1
                  },
                  {
                    label: "离线",
                    value: 0
                  }
                ]
              }
            },
            ],
      };
    },
    async created() {
      console.log("L21BX created!!");
      this.$nextTick(() => {
        this.$refs.appList.onRefresh();
      })
      let bindPlatformType = this.$props.bindPlatformType;
      if(bindPlatformType == 2) {
        this.opt.search = this.search2;
      }else {
        this.opt.search = this.search1;
        try {
        // let groupList = await cabinetUtil.getStoreList();
        // this.opt.search.forEach(item => {
        //   if (item.key == 'groupName') {
        //     item.opt.list = groupList;
        //   }
        // })
        this.merchantFlag = this.getLoginType('loginType');
        if (this.merchantFlag) {
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = merchantList;
            }
          })
        }
      } catch (error) {
        console.log(error)
      }
      }
      

    },
    activated() {
      console.log("L21BX activated!!");
    },
    deactivated() {
      this.dialogVisibleUpdate = false;
    },
    mounted() {},
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          mac: opt.searchForm.mac,
          location: opt.searchForm.location,
          address: opt.searchForm.address,
          onlineState: opt.searchForm.onlineState,
          type: 6, //L21BX
          size: 2, //小
          tenantId: this.$props.bindPlatformType,
          ...opt.searchForm
        };
        this.post("/mall-service/counter/v2/page", dto, {
          isUseResponse: true
        }).then(res => {
          if(this.$props.bindPlatformType == 2) {
            res.data.data.data.forEach(item => {
              item.onlineStateStr = ["离线", "在线"][item.onlineState];
              item.batteryStr = item.battery ? item.battery + '%' : '--';
              item.version = item.version ? item.version : '--';
              item.merchantName = '--';
              item.goodsStateStr = '--';
              item.location = '--';
            });
          }else {
            res.data.data.data.forEach(item => {
              item.onlineStateStr = ["离线", "在线"][item.onlineState];
              item.goodsStateStr = item.shoppingNums && item.shoppingNums > 0 ? ["缺货", "满货"][item.goodsState] :
                '--';
              item.batteryStr = item.battery ? item.battery + '%' : '--';
              item.version = item.version ? item.version : '--';
              item.merchantName = item.merchantName ? item.merchantName : '--';
            });
          }
          
          opt.cb(res.data.data);
        });
        if(opt.searchForm.merchantId) {
          this.getStoreList(opt.searchForm.merchantId);
        }else{
          this.getStoreList();
        }
      },
      //获取门店列表
      getStoreList(data) {
        let dto = {
          currentPage: 1,
          size: 99999,
        };
        if(data) {
          dto.merchantId = data
        }
        this.post("/mall-service/v1/counterStores/getAllList", dto, {
          isUseResponse: true,
        }).then(res => {
          let storeList = res.data.data;
          storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
          storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
          this.opt.search.forEach(item => {
            if (item.key == 'groupName') {
              item.opt.list = storeList;
            }
          })
        });
      },
      closeFun() {
        this.dialogVisibleUpdate = false;
      },
      sureBtn() {
        this.dialogVisibleUpdate = false;
        cabinetUtil.upgrade(this.rowData);
      },
    }
  };
</script>
<style lang="scss" scoped>
  .L21BX-list {
    height: calc(100% - 126px);
  }

  .dialog_content {
    text-align: center;
    padding-bottom: 40px;
  }

  .dialog_bottom {
    text-align: center;
  }
</style>