<template>
  <div class="L25A-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
import cabinetUtil from "@utils/cabinetUtil.js";
export default {
  props: {
    containerType: {
      type: Number,
      default: "",
    },
    bindPlatformType: {
      type: Number,
      default: "",
    },
  },
  watch: {
    containerType: {
      handler(newValue, oldValue) {
        this.containerType = newValue;
        // 解决非初次进入该页面不会触发crested导致没触发请求问题
        this.$refs.appList.onRefresh();
      },
    },
  },
  data() {
    let _this = this;
    return {
      opt: {
        title: "",
        search: [],
        valType: '',
        columns: [
          {
            label: "芯片ID",
            key: "mac",
          },
          {
            label: "名称",
            key: "name",
          },
          {
            label: "IP",
            key: "ip",
          },
          {
            label: "商家名称",
            key: "merchantName",
          },
          {
            label: "货道数量",
            key: "totalNums",
          },
          {
            label: "门店位置",
            key: "location",
          },
          {
            label: "货柜位置",
            key: "address",
          },
          {
            label: "电量",
            key: "batteryStr",
          },
          {
            label: "绑定时间",
            key: "createdTime",
          },
          {
            label: "联网状态",
            key: "onlineStateStr",
          },
          {
            label: "货品状态",
            key: "goodsStateStr",
          },
          {
            label: "固件版本",
            key: "version",
          },
          {
            label: "操作",
            key: "action",
            type: "action-but",
            opt: {
              list: [
                {
                  label: "详情",
                  on(row) {
                    let type;
                    if(row.type == 20) {
                      type = 'T160'
                    }else if(row.type == 21) {
                      type = 'T254'
                    }else if(row.type == 2014) {
                      type = 'T260'
                    }
                    _this.$router.push({
                      path: "/main/containerManagement/detail/" + type,
                      query: {
                        id: row.id,
                        type: row.type,
                        mac: row.mac,
                        storeAddress: row.location,
                        storeName: row.groupName,
                      },
                    });
                  },
                },
              ],
            },
          },
        ],
        buttons: [
          {
            type: 2,
            on(data) {
              if (data.ids.length > 1) {
                return _this.$message({
                  showClose: true,
                  message: "不支持多个删除,只支持单个删除!",
                  type: "warning",
                });
              }
              const h = _this.$createElement;
              _this
                .$msgbox({
                  title: "删除提示",
                  message: h("p", null, [
                    h(
                      "span",
                      {
                        style: "color: #333;font-size: 16px;margin-left:98px",
                      },
                      "确定要删除货柜吗？"
                    ),
                  ]),
                  showCancelButton: true,
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                })
                .then((action) => {
                  _this
                    .get("mall-service/counter/delete/" + data.ids[0])
                    .then((res) => {
                      _this.$message({
                        showClose: true,
                        message: "删除货柜成功！",
                        type: "success",
                      });
                      data.refresh();
                    });
                });
            },
          },
        ],
        exportButtons: [
          {
            type: "Export",
            url: this.EXport + "/mall-service/counter/export",
            listName: "货柜列表",
          },
        ],
      },
      roomList: [],
      merchantFlag: false,
      search1: [
        {
          key: "groupName",
          label: "门店名称",
          type: "selectCity",
          opt: {
            list: [],
          },
        },
        {
          key: "mac",
          label: "芯片ID",
          maxlength: 32,
        },
        {
          key: "location",
          label: "门店位置",
          maxlength: 200,
        },
        {
          key: "address",
          label: "货柜位置",
        },
        {
          key: "onlineState",
          label: "联网状态",
          type: "select-no-filterable",
          opt: {
            list: [
              {
                label: "在线",
                value: 1,
              },
              {
                label: "离线",
                value: 0,
              },
            ],
          },
        },
        {
          key: "merchantId",
          label: "商家名称",
          type: "remoteSearchById",
          opt: {
            list: [],
          },
        },
        {
          type: "Export",
          url: this.EXport + "/mall-service/counter/export",
          listName: "货柜列表",
        },
      ],
      search2: [
        {
          key: "mac",
          label: "芯片ID",
          maxlength: 32,
        },
        {
          key: "onlineState",
          label: "联网状态",
          type: "select-no-filterable",
          opt: {
            list: [
              {
                label: "在线",
                value: 1,
              },
              {
                label: "离线",
                value: 0,
              },
            ],
          },
        },
        {
          type: "Export",
          url: this.EXport + "/mall-service/counter/export",
          listName: "货柜列表",
        },
      ],
    };
  },
  async created() {
    this.$nextTick(() => {
      this.$refs.appList.onRefresh();
    });
    if (this.$props.bindPlatformType == 2) {
      this.opt.search = this.search2;
    } else {
      this.opt.search = this.search1;
      try {
        let groupList = await cabinetUtil.getStoreList();
        this.opt.search.forEach((item) => {
          if (item.key == "groupName") {
            item.opt.list = groupList;
          }
        });
        this.merchantFlag = this.getLoginType("loginType");
        if (this.merchantFlag) {
          this.opt.search.forEach((item, index) => {
            if (item.key == "merchantId") {
              this.opt.search.splice(index, 1);
            }
          });
          this.opt.columns.forEach((item, index) => {
            if (item.key == "merchantName") {
              this.opt.columns.splice(index, 1);
            }
          });
        } else {
          let merchantList = await cabinetUtil.getMerchantList();
          this.opt.search.forEach((item, index) => {
            if (item.key == "merchantId") {
              item.opt.list = merchantList;
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  activated() {
  },
  mounted() {},
  methods: {
    onGet(opt) {
      if(this.valType !== this.$props.containerType){
          this.$refs.appList.onSearchReset();
          this.valType = this.$props.containerType
          return
      }
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        groupName: opt.searchForm.groupName,
        mac: opt.searchForm.mac,
        location: opt.searchForm.location,
        address: opt.searchForm.address,
        onlineState: opt.searchForm.onlineState,
        type: this.$props.containerType,
        merchantId: opt.searchForm.merchantId,
        tenantId: this.$props.bindPlatformType,
        ...opt.searchForm,
      };
      this.post("mall-service/counter/v2/page", dto, {
        isUseResponse: true,
      }).then((res) => {
        if (this.$props.bindPlatformType == 2) {
          res.data.data.data.forEach((item) => {
            item.onlineStateStr = ["离线", "在线"][item.onlineState];
            item.batteryStr = item.battery ? item.battery + "%" : "--";
            item.version = item.version ? item.version : "--";
            item.merchantName = "--";
            item.goodsStateStr = "--";
            item.location = "--";
          });
        } else {
          res.data.data.data.forEach((item) => {
            item.onlineStateStr = ["离线", "在线"][item.onlineState];
            item.batteryStr = item.battery ? item.battery + "%" : "--";
            item.version = item.version ? item.version : "--";
            item.merchantName = item.merchantName ? item.merchantName : "--";
            item.goodsStateStr =
              item.shoppingNums && item.shoppingNums > 0
                ? ["缺货", "满货"][item.goodsState]
                : "--";
          });
        }

        opt.cb(res.data.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.L25A-list {
  height: calc(100% - 126px);
}

.dialog_content {
  text-align: center;
  padding-bottom: 40px;
}

.dialog_bottom {
  text-align: center;
}
</style>