<template>
  <div class="containerManagement-list">
    <div class="title">
      <app-header :title="title" :isShowBack="false"></app-header>
    </div>
    <!-- 平台登录显示绑定平台查询条件 -->
    <div v-if="loginType == 1" style="padding-bottom: 30px; display: inline-block;margin-right: 20px;">
      <span style="padding-right: 20px">绑定平台</span>
      <el-select
        style="width: 240px; display: inline-block"
        v-model="bindPlatformType"
        @change="changePlatformType(bindPlatformType)"
      >
        <el-option
          v-for="item in bindPlatformTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div style="padding-bottom: 30px; display: inline-block">
      <span style="padding-right: 20px">货柜类型</span>
      <el-select
        style="width: 240px; display: inline-block"
        v-model="containerType"
        @change="changeContainerType(containerType)"
      >
        <el-option
          v-for="item in containerTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div v-if="loginType == 1" class="containerManagement-firmwave">
      <el-button type="primary" @click="goFirmwareManage()">固件管理</el-button>
    </div>
    <template v-if="bindPlatformType == 1">
      <L2K v-if="containerType == 1" :bindPlatformType="bindPlatformType"></L2K>
      <L21 v-if="containerType == 2" :bindPlatformType="bindPlatformType"></L21>
      <L22 v-if="containerType == 3" :bindPlatformType="bindPlatformType"></L22>
      <L21BD v-if="containerType == 4" :bindPlatformType="bindPlatformType"></L21BD>
      <L21BX v-if="containerType == 5" :bindPlatformType="bindPlatformType"></L21BX>
      <L22BD v-if="containerType == 6" :bindPlatformType="bindPlatformType"></L22BD>
      <L22BX v-if="containerType == 7" :bindPlatformType="bindPlatformType"></L22BX>
      <!-- L25B和L25功能一样  外观不同 -->
      <L25
        v-if="
          containerType == 8 ||
          containerType == 10 ||
          containerType == 16 ||
          containerType == 17 ||
          containerType == 18 ||
          containerType == 2001 ||
          containerType == 2004 ||
          containerType == 2005 ||
          containerType == 2006 ||
          containerType == 2007 ||
          containerType == 2008 ||
          containerType == 2009 ||
          containerType == 2010 ||
          containerType == 2011 ||
          containerType == 2012 ||
          containerType == 2013
        "
        :containerType="containerType"
        :bindPlatformType="bindPlatformType"
      ></L25>
      <L25A v-if="showL25AFlage" :containerType="containerType" :bindPlatformType="bindPlatformType"></L25A>
      <L25AB v-if="containerType == 19" :containerType="containerType" :bindPlatformType="bindPlatformType"></L25AB>
      <T160
        v-if="containerType == 20 || containerType == 21 || containerType == 2014"
        :containerType="containerType"
        :bindPlatformType="bindPlatformType"
      ></T160>
    </template>
     <template v-if="bindPlatformType == 2">
      <NEWL2K v-if="containerType == 1" :bindPlatformType="bindPlatformType"></NEWL2K>
      <NEWL21 v-if="containerType == 2" :bindPlatformType="bindPlatformType"></NEWL21>
      <NEWL22 v-if="containerType == 3" :bindPlatformType="bindPlatformType"></NEWL22>
      <NEWL21BD v-if="containerType == 4" :bindPlatformType="bindPlatformType"></NEWL21BD>
      <NEWL21BX v-if="containerType == 5" :bindPlatformType="bindPlatformType"></NEWL21BX>
      <NEWL22BD v-if="containerType == 6" :bindPlatformType="bindPlatformType"></NEWL22BD>
      <NEWL22BX v-if="containerType == 7" :bindPlatformType="bindPlatformType"></NEWL22BX>
      <!-- L25B和L25功能一样  外观不同 -->
      <NEWL25
        v-if="
          containerType == 8 ||
          containerType == 10 ||
          containerType == 16 ||
          containerType == 17 ||
          containerType == 18 ||
          containerType == 2001 ||
          containerType == 2004 ||
          containerType == 2005 ||
          containerType == 2006 ||
          containerType == 2007 ||
          containerType == 2008 ||
          containerType == 2009 ||
          containerType == 2010 ||
          containerType == 2011 ||
          containerType == 2012 ||
          containerType == 2013
        "
        :containerType="containerType"
        :bindPlatformType="bindPlatformType"
      ></NEWL25>
      <NEWL25A v-if="showL25AFlage" :containerType="containerType" :bindPlatformType="bindPlatformType"></NEWL25A>
      <NEWL25AB v-if="containerType == 19" :containerType="containerType" :bindPlatformType="bindPlatformType"></NEWL25AB>
      <NEWT160
        v-if="containerType == 20 || containerType == 21 || containerType == 2014"
        :containerType="containerType"
        :bindPlatformType="bindPlatformType"
      ></NEWT160>
    </template>
  </div>
</template>
<script>
import L2K from "./L2K";
import L21 from "./L21";
import L22 from "./L22";
import L21BD from "./L21BD";
import L21BX from "./L21BX";
import L22BD from "./L22BD";
import L22BX from "./L22BX";
import L25 from "./L25";
import L25A from "./L25A";
import T160 from "./T160";
import L25AB from "./L25AB";
// 切换平台类型时不会重新加载子组件，因此另定义个组件名称
import NEWL2K from "./L2K";
import NEWL21 from "./L21";
import NEWL22 from "./L22";
import NEWL21BD from "./L21BD";
import NEWL21BX from "./L21BX";
import NEWL22BD from "./L22BD";
import NEWL22BX from "./L22BX";
import NEWL25 from "./L25";
import NEWL25A from "./L25A";
import NEWT160 from "./T160";
import NEWL25AB from "./L25AB";
export default {
  components: {
    L2K,
    L21,
    L22,
    L21BD,
    L21BX,
    L22BD,
    L22BX,
    L25,
    L25A,
    T160,
    L25AB,
    NEWL2K,
    NEWL21,
    NEWL22,
    NEWL21BD,
    NEWL21BX,
    NEWL22BD,
    NEWL22BX,
    NEWL25,
    NEWL25A,
    NEWT160,
    NEWL25AB,
  },
  data() {
    let _this = this;
    return {
      loginType: "",
      showL25AFlage: false,
      containerType: 8,
      containerTypeList: [
        {
          label: "L2K(售卡)",
          value: 1,
        },
        {
          label: "L21(单门)",
          value: 2,
        },
        {
          label: "L22(多门)",
          value: 3,
        },
        {
          label: "L21BD(单门/大)",
          value: 4,
        },
        {
          label: "L21BX(单门/小)",
          value: 5,
        },
        {
          label: "L22BD(多门/大)",
          value: 6,
        },
        {
          label: "L22BX(多门/小)",
          value: 7,
        },
        {
          label: "L25（售货）",
          value: 8,
        },
        {
          label: "L25A（储物）",
          value: 9,
        },
        {
          label: "L25B（售货）",
          value: 10,
        },
        {
          label: "L25C（储物）",
          value: 11,
        },
        {
          label: "L25E（售货）",
          value: 2004,
        },
        {
          label: "L25F（售货）",
          value: 2005,
        },
        {
          label: "L25AF（储物）",
          value: 12,
        },
        {
          label: "L25CF（储物）",
          value: 13,
        },
        {
          label: "L25AK（储物）",
          value: 14,
        },
        {
          label: "L25CK（储物）",
          value: 15,
        },
        {
          label: "L25BK（售货）",
          value: 16,
        },
        {
          label: "L25MK（售货）",
          value: 2007,
        },
        {
          label: "L25BMK（售货）",
          value: 2013,
        },
        {
          label: "L25-6（售货）",
          value: 2009,
        },
        {
          label: "L25-12（售货）",
          value: 2010,
        },
        {
          label: "L25-18（售货）",
          value: 2011,
        },
        {
          label: "L25-24（售货）",
          value: 2012,
        },
        {
          label: "L25D（售货）",
          value: 18,
        },
        {
          label: "L25AB（换电）",
          value: 19,
        },
        {
          label: "L25E85（售货）",
          value: 2008,
        },
        {
          label: "T160（售货）",
          value: 20,
        },
        {
          label: "T254（售货）",
          value: 21,
        },
        {
          label: "T260（售货）",
          value: 2014,
        },
        {
          label: "L27（旋转柜）",
          value: 2001,
        },
        {
          label: "L27N（旋转柜）",
          value: 2006,
        },
        {
          label: "L25AM（密码储物柜）",
          value: 2016,
        },
      ],
      bindPlatformTypeList: [
        {
          label: "云数商城",
          value: 1,
        },
        {
          label: "威富商城",
          value: 2,
        },
      ],
      bindPlatformType: 1,
      title: "货柜管理",
    };
  },
  activated() {
    this.loginType = this.cache.getLS("loginType");
    console.log("货柜管理activated");
    let bindPlatformTypeVal = this.cache.get("bindPlatformTypeVal");
    if (!bindPlatformTypeVal) {
      this.bindPlatformType = 1;
    }
    let containerTypeVal = this.cache.get("containerTypeVal");
    if (!containerTypeVal) {
      this.containerType = 8;
      this.cache.set("containerTypeVal", this.containerType);
    }
    if (this.containerType == 8) {
      // 初始化
      // 解决 先点售货柜后再点击储物柜后刷新浏览器会出现两个表格的情况
      this.showL25AFlage = false;
      this.cache.set("containerTypeVal", this.containerType);
    } else {
      if ([9, 11, 12, 13, 14, 15,2016].indexOf(Number(containerTypeVal)) != -1) {
        this.showL25AFlage = true;
      } else {
        this.showL25AFlage = false;
      }
    }
  },
  created() {
    console.log("货柜管理created");
    let containerTypeVal = this.cache.get("containerTypeVal");
    if (!containerTypeVal) {
      this.containerType = 8;
      this.cache.set("containerTypeVal", this.containerType);
    }
  },
  mounted() {
    console.log("货柜管理mounted");
  },
  deactivated() {
    console.log("离开货柜管理列表");
    let pathList = this.$route.path.split("/");
    if (pathList[2] != "containerManagement") {
      this.cache.del("containerTypeVal");
      this.cache.del("bindPlatformTypeVal");
    }
  },
  methods: {
    changeContainerType(val) {
      this.containerType = val;
      this.cache.set("containerTypeVal", val);
      if ([9, 11, 12, 13, 14, 15,2016].indexOf(this.containerType) != -1) {
        this.showL25AFlage = true;
      } else {
        this.showL25AFlage = false;
      }
    },
    changePlatformType(val) {
      this.bindPlatformType = val;
      this.cache.set("bindPlatformTypeVal", val);
    },
    goFirmwareManage() {
      this.$router.push("/main/firmware/list");
    },
  },
};
</script>
<style lang="scss">
.containerManagement-list {
  .danger {
    color: #f56c6c;
  }

  .titleSpans {
    padding-bottom: 20px;
  }

  .titleSpan {
    color: #606266;
  }

  .info1 {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;

    .txt {
      margin-right: 20px;
    }
  }

  .info {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #606266;
    background-color: #e4e7ed;

    .txt {
      margin-right: 20px;
    }
  }

  // tab样式
  .tab-ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    height: 42px;
    box-sizing: border-box;
    margin-bottom: 40px;
    width: 100%;
    border-bottom: 2px solid #e4e7ed;

    li {
      cursor: pointer;
      padding-left: 18px;
      padding-right: 18px;
      height: 42px;
      line-height: 42px;
      float: left;
      text-align: center;
      margin-right: 13px;
      font-size: 15px;
      background-color: #ffffff;
      color: #303133;
      box-sizing: border-box;
      border-bottom: 2px solid #e4e7ed;

      &.ative {
        // color: #ffffff;
        // background: #5e74f1;
        color: #30bac1;
        border-bottom: 2px solid #30bac1;
      }
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  .landlord-manage-box .el-tab-pane {
    height: 650px;
  }

  .containerManagement-firmwave {
    display: inline-block;
    margin-left: 46px;
  }
}
</style>