var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "L21-list" },
    [
      _c("app-list", {
        ref: "appList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            width: "500px",
            top: "30vh",
            visible: _vm.dialogVisibleUpdate,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleUpdate = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialog_content" }, [
            _c("div", [_vm._v("执行升级大约需要2分钟，请不要断电")])
          ]),
          _c(
            "div",
            { staticClass: "dialog_bottom" },
            [
              _c("el-button", { on: { click: _vm.closeFun } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sureBtn } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }